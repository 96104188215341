<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class=" wp-image-23098 aligncenter" src="@/assets/news/6/1.png" alt="图片" width="679" height="334"  sizes="(max-width: 679px) 100vw, 679px"></p>
        <section>
          <section>
            <p style="text-align: center;">&nbsp;预科线280分！</p>
            <p style="text-align: center;">本科线300分！</p>
            <p style="text-align: center;">一本线400分！</p>
            <p style="text-align: center;">艺术和体育的特长生</p>
            <p style="text-align: center;">甚至低到了200分！</p>
            <p style="text-align: center;">2021 华侨生联考！</p>
            <h3 style="text-align: center;">&nbsp; &nbsp;录取较低分数线-新鲜出炉啦！</h3>
            <p style="text-align: center;">通往名校的途径！</p>
            <p style="text-align: center;">广纳人才的赛道！</p>
            <p style="text-align: center;"><strong>多所优秀高校低分录取&nbsp;</strong></p>
            <p style="text-align: center;"><strong>华侨生的优势“真香”<br>
            </strong></p>
            <p>&nbsp;</p>
            <p>2021年普通高等学校联合招收华侨港澳台学生（以下简称全国联招）本科批次录取分数线如下：</p>
            <ul class="list-paddingleft-2">
              <li>普通类院校（专业）文史、理工类：300分（其中，部分院校执行400分，院校名单见附件）。</li>
              <li>艺术类院校（专业）文史、理工类：200分。</li>
              <li>体育类院校（专业）文史、理工类：200分。</li>
            </ul>
            <p><img loading="lazy" class="wp-image-23099 aligncenter" src="@/assets/news/6/2.jpg" alt="图片" width="753" height="830"  sizes="(max-width: 753px) 100vw, 753px"></p>
            <p>（附件：2021年全国联招本科批次普通类录取分数线低至400分的院校名单）</p>
            <p>今年，对华侨生较低录取分数线为400分的本科院校多达131所，其中依旧不乏清华、北大、人大、复旦、交大这样的学府，华侨生联考政策，帮助很多华侨学子们提前实现弯道超车！</p>
            <p><img loading="lazy" class=" wp-image-23100 aligncenter" src="@/assets/news/6/3.jpg" alt="图片" width="712" height="265"  sizes="(max-width: 712px) 100vw, 712px"></p>
            <p style="text-align: center;">（图片源自网络数据监测）</p>
            <p>在高考这场“漩涡”式竞争中，只要你上了赛道，就没有撤退可言。多少莘莘学子点灯熬油只为这一战，打开心仪名校的大门。据教育部公布统计数据，2021年高考报名人数达到1078万人，连续第3年超过千万。截至发稿前，安徽省的#高考分数线也已经公布，文史：一本560分，二本519分；理工：一本488分，二本415分。</p>
            <p><img loading="lazy" class="wp-image-23101 aligncenter" src="@/assets/news/6/4.jpg" alt="图片" width="731" height="531"  sizes="(max-width: 731px) 100vw, 731px"></p>
            <p>而2021年参加华侨生联考的人数却不足5000人（注：不占用高考名额），千和万的计量单位对比，不仅在人数上优势明显，而且录取分数线更是低到400分可以上“211”、“985”。不得不感叹，这赛道！“真香”！</p>
            <p><strong>也正因为华侨生联考政策宽松、科目少、考试简单、录取分数低、录取比例高等优势，一直被视为华侨学生就读清华北大等中国名校的一条捷径，颇受中国高净值家庭的关注。</strong></p>
            <p><strong>那么，华侨生参加高考真的相当于开挂吗？参加华侨生联考需要满足那些条件呢？</strong></p>
            <p>&nbsp;</p>
            <section>华侨生联考全称“中华人民共和国普通高等院校联合招收华侨及港澳台地区学生考试”，是教育部专为港澳台侨学生设计的报考中国内地大学之主要途径。设立至今已有近30年历史，华侨生联考与高校普通高考招生指标分开，不构成任何竞争关系。<strong>单独命题，考纲独立、单独考试，提前录取。</strong><p></p>
            </section>
            <p><strong>提前部署有规划&nbsp;选择比努力更重要</strong></p>
            <p>现在这个“暴走鸡娃”的时代，很多人挤破头想要买到一个动辄几百上千万的学区房，有关教育、学区房的话题从来没有低热度的时候。上班一天后劳累的家长们还要带着孩子奔波于各个补习班，回家之后仅仅几个小时的休息调整时间也要陪伴孩子上着几百块一节的网课，似乎已经成为了每个家庭的缩影。</p>
            <p><img loading="lazy" class="wp-image-23102 aligncenter" src="@/assets/news/6/5.jpg" alt="图片" width="693" height="462"  sizes="(max-width: 693px) 100vw, 693px"></p>
            <p>与其被动焦虑，不如主动尝试新的选择：<strong>拥有海外身份，让孩子参加华侨生联考无疑是高考之外的一条捷径。</strong></p>
            <p><strong>但机会是留给有准备的人的，置办身份需要一定的条件与时间，需要家长们提前部署与规划。</strong></p>
            <p>所以跃迁帮有兴趣的家庭整理归纳了一些资料，供大家参考：</p>
            <p>注意⚠：因2022年华侨生联考具体政策官方还未公布，由于往年政策走向往往是微调而非大幅度变动，所以我们暂参考2021年华侨生联考报名条件。</p>
          </section>
          <section><strong>报名条件</strong></section>
          <section>符合下列条件之一且具有高中毕业文化程度（须为学历教育）的人员，可以申请报名普通高校联合招收华侨港澳台学生（以下简称全国联招）：</section>
          <section>
            <section>
              <section>1.港澳地区考生，具有①《港澳居民来往内地通行证》或《港澳居民居住证》和②香港或澳门居民身份证。2.台湾地区考生，具有①《台湾居民来往大陆通行证》或《台湾居民居住证》和②在台湾居住的有效身份证明。<p></p>
                <p>3.华侨考生，考生本人及其父母一方均须取得住在国长期或者永久居留权，<strong>并已在住在国连续居留2年，两年内累计居留不少于18个月，其中考生本人须在报名前2年内</strong>（即2019年4月1日-2021年3月31日）在住在国实际累计居留不少于18个月。</p>
                <p>若考生本人或其父母一方未取得住在国长期或永久居留权，但已取得住在国连续5年以上（含5年）合法居留资格、5年内在住在国累计居留不少于30个月，且考生本人在报名前5年内（即2016年4月1日-2021年3月31日）在住在国实际累计居留不少于30个月的，也可参加报名。</p>
              </section>
            </section>
          </section>
          <section>注1：须在教育主管部门认可的高级中等学校接受高中阶段教育，非学历教育经历不符合报名条件。<br>
            注2：指连续的两个自然年内。<br>
            注3：1个月按30天计算，不少于18个月即不少于540天。<br>
            注4：指连续的五个自然年内。<br>
            注5：1个月按30天计算，不少于30个月即不少于900天。根据国家有关规定，中国公民出国留学（包括公派和自费）在外学习期间，或因公务出国（包括外派劳务人员）在外工作期间，均不视为华侨。<p></p>
          </section>
          <section>2021年的政策较往年没有很大幅度的变化，海外永居身份并符合居住要求的孩子们依旧符合华侨生政策。其实，孩子要想参加华侨生联考，<strong>较为快捷的方式便是取得外国长期或永久居留权。</strong><p></p>
            <p><img loading="lazy" class="wp-image-23103 aligncenter" src="@/assets/news/6/6.jpg" alt="图片" width="646" height="435"  sizes="(max-width: 646px) 100vw, 646px"></p>
            <p>现如今，拥有一个海外身份已经成为高净值人士的标配之一，他们有的是为了自己将来的养老提前做规划，有的则是为了扩展自己的事业版图，但其中更多的是为了子女可以享受更优质的教育资源，接受更先进的教育理念。</p>
            <p><strong>此外，如果能提前规划海外身份，除了海外优质的教育资源外，即便选择回国深造，华侨身份的学生也可以享受低分录读国内名校的福利。</strong></p>
            <p>您的孩子已经开始备战高考了吗？或者，如果您的孩子还小，您是否在为中考感到不安和迷茫呢？对于高考和华侨生联考，您有什么独特观点要分享呢？您也有帮助孩子弯道超车的计划吗？欢迎来评论区和我们讨论吧！</p>
            <p>（注*：关于政策部分一切以官方消息为准）</p>
            <section><strong>规划海外身份可选方式</strong></section>
            <p style="text-align: center;"><strong>爱尔兰投资移民</strong></p>
            <p style="text-align: center;"><strong>一份投资，三重身份</strong></p>
            <p><img loading="lazy" class="alignnone size-full wp-image-23104 aligncenter" src="@/assets/news/6/7.jpg" alt="图片" width="573" height="442"></p>
            <p>● 先获批后投资，资金安全有保障；</p>
            <p>●&nbsp;审批速度快；</p>
            <p>●&nbsp;无年龄、学历、语言要求，无需管理经验；</p>
            <p>●&nbsp;爱尔兰投资移民无移民监要求，每年需登陆爱尔兰一次即可；</p>
            <p>●&nbsp;爱尔兰教育体制全球排名第七*，移民子女享优质英式教育；</p>
            <p>*资料来源：2019年IMD世界竞争力排名。</p>
            <p>●&nbsp;跨国公司欧洲总部林立，移民子女享优质就业平台，签证美好未来；</p>
            <p>●&nbsp;爱尔兰公民可坐享欧盟与英国双边福利，一份投资获三重身份。</p>
          </section>
        </section>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news6",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:6,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>